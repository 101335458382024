// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

/**
 * add product to wish list feature
 */
class AddProductToWishListInit {

	// local storage keys
	static LOCAL_STORAGE_KEY_PRODUCT_NEED_TO_BE_ADDED_TO_WISH_LIST_URL = 'productNeedToBeAddedToWishListUrl';

	// constructor
	constructor() {
		console.log('constructor');

		// default user is signed in
		this.userIsSignedIn = 0;

		// get product form
		this.productOptionsForm = document.querySelector('.product-details form');

		// wish list btn
		const addProductToWishListBtns = document.querySelectorAll('.add-product-to-wish-list-btn');

		if (addProductToWishListBtns.length > 0) {
			for (const addProductToWishListBtn of addProductToWishListBtns) {
				addProductToWishListBtn.addEventListener('click', this.addProductToWishListButtonOnClick.bind(this, addProductToWishListBtn));

				// check user is signed in
				this.userIsSignedIn = parseInt(addProductToWishListBtn.dataset.userIsSignedIn);
			}

			// modals
			this.wishListUserIsNotSignedInModal = $('#wish-list-user-is-not-signed-in-modal');

			// get productNeedToBeAddedToWishListUrl
			const productNeedToBeAddedToWishListUrl = localStorage.getItem(AddProductToWishListInit.LOCAL_STORAGE_KEY_PRODUCT_NEED_TO_BE_ADDED_TO_WISH_LIST_URL);

			// check productNeedToBeAddedToWishListUrl is null from cabinet home page
			if (productNeedToBeAddedToWishListUrl !== null && productNeedToBeAddedToWishListUrl !== 'null') {

				// find button
				const addProductToWishListBtn = document.querySelector('.add-product-to-wish-list-btn[data-product-url="' + productNeedToBeAddedToWishListUrl + '"]');
				if (addProductToWishListBtn !== undefined) {
					// remove product url from local storage
					localStorage.setItem(AddProductToWishListInit.LOCAL_STORAGE_KEY_PRODUCT_NEED_TO_BE_ADDED_TO_WISH_LIST_URL, null);

					// check user is signed in
					if (this.userIsSignedIn === 1) {

						// add product to wish list and keep shopping
						this.addProductToWishListButtonOnClick(addProductToWishListBtn);
					}
				}
			}
		}
	}

	// add to wish list button click
	addProductToWishListButtonOnClick(button, event) {
		// check user is signed in
		if (this.userIsSignedIn === 0) {
			// set return url value
			const signInReturnUrlInput = document.querySelector('#sign-in-return-url');
			const signUpReturnUrlInput = document.querySelector('#sign-up-return-url');
			signInReturnUrlInput.value = location.href;
			signUpReturnUrlInput.value = location.href;

			// show sign in modal
			this.wishListUserIsNotSignedInModal.modal('show');

			// save product url with product need to be added to wish list
			localStorage.setItem(AddProductToWishListInit.LOCAL_STORAGE_KEY_PRODUCT_NEED_TO_BE_ADDED_TO_WISH_LIST_URL, button.dataset.productUrl);
		} else {
			// check that button has product_id
			const productId = button.dataset.productId;
			if (productId !== undefined) {
				this.productOptionsForm.prod_id.value = productId;
			}

			// show loader
			this.showBackgroundLoader();
		}

		// prevent default
		if (event !== undefined) {
			event.preventDefault();
		}
	}

	// show loader
	showBackgroundLoader() {
		Swal.fire({
			title: 'Saving product to wish list..',
			allowOutsideClick: () => !Swal.isLoading(),
			didOpen: () => {
				// show loader
				Swal.showLoading();

				// update asc_action value
				this.productOptionsForm.asc_action.value = 'AddToWishlist';

				// send ajax request to the server
				$.ajax({
					url: '/wishlist.php',
					type: 'POST',
					dataType: 'json',
					processData: false,
					contentType: false,
					data: new FormData(this.productOptionsForm)
				});

				// show sweetalert message
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Product is successfully added to wish list!',
					showCloseButton: true,
					showCancelButton: true,
					focusConfirm: false,
					confirmButtonText: '<i class="fa fa-fw fa-heart"></i> Great! Go to Wish List!',
					confirmButtonAriaLabel: 'Great! Go to Wish List!',
					cancelButtonText: '<i class="fa fa-fw fa-check"></i> or Continue shopping',
					cancelButtonAriaLabel: 'or Continue shopping'
				}).then((result) => {
					// redirect to the cart
					if (result.value) {
						location.href = '/wishlist.php';
					}
				});
			},
		});
	}
}

/**
 * working with products in wish list
 */
class ManageWishListInit {

	// constructor
	constructor() {
		// wish list product items
		const wishListProductItems = document.querySelectorAll('.wish-list-product-item');
		for (const wishListProductItem of wishListProductItems) {
			// attach click handler
			wishListProductItem.addEventListener('click', this.showWishListProductItemDetailsModal.bind(this, wishListProductItem));
		}

		// share wish list
		const shareWishListBtn = document.querySelector('.wishlist-share-btn');
		shareWishListBtn.addEventListener('click', this.showShareWishListModal.bind(this, shareWishListBtn));
	}

	// show share wish list modal
	showShareWishListModal(shareWishListBtn) {
		const customerId = document.querySelector('.wishlist-content').dataset.customerId;
		const wishListUrl = 'https://mensdesignershoe.com/wishlist-share?id=' + customerId;

		// show modal
		Swal.fire({
			title: 'Share wishlist with your friend',
			html:
				'<div class="content-block">' +
				'<div class="form-label">Email to:</div>' +
				'<input class="form-control input-lg send-wishlist-to-email-input" type="email" placeholder="Fill you Email!" value="">' +
				'<div class="hint-error hidden">Enter a valid email address!</div>' +
				'<button class="btn btn-lg btn-primary send-wishlist-to-email-btn"><i class="fa fa-fw fa-check"></i> Send</button>' +
				'</div>' +
				'<div class="content-block">' +
				'<div class="form-label">Share your link:</div>' +
				'<input class="form-control input-lg copy-wishlist-url-to-clipboard-input" type="text" value="' + wishListUrl + '">' +
				'<button class="btn btn-lg btn-primary copy-wishlist-url-to-clipboard-btn"><i class="fa fa-fw fa-clipboard"></i> Copy</button>' +
				'</div>' +
				'</div>',
			showConfirmButton: false,
			showCloseButton: false,
			showCancelButton: false,
			focusConfirm: false,
			customClass: {
				container: 'share-wish-list-popup',
			}
		});

		// send wish list email to friend
		const sendWishListToEmailBtn = document.querySelector('.send-wishlist-to-email-btn');
		sendWishListToEmailBtn.addEventListener('click', () => {
			const input = document.querySelector('.send-wishlist-to-email-input');
			const errorHint = document.querySelector('.hint-error');
			const email = input.value;
			if (this.checkEmailIsValid(email)) {
				errorHint.classList.add('hidden');

				// show sending alert
				Swal.fire({
					title: 'Sending wishlist to email..',
					allowOutsideClick: () => !Swal.isLoading(),
					didOpen: () => {
						// show loader
						Swal.showLoading();

						// form data
						const formData = new FormData();

						// set product id
						formData.append('email', email);

						// asc_action
						formData.append('asc_action', 'SendWishlist');

						// send ajax request
						$.ajax({
							url: '/wishlist.php',
							type: 'POST',
							dataType: 'json',
							processData: false,
							contentType: false,
							data: formData,
							statusCode: {
								// request is done
								200: function () {
									// show sweetalert message
									Swal.fire({
										icon: 'success',
										title: 'Success',
										text: 'Email is successfully sent!',
										showCloseButton: true,
										showCancelButton: false,
										focusConfirm: false,
										confirmButtonText: '<i class="fa fa-fw fa-heart"></i> Great!',
										confirmButtonAriaLabel: 'Great!',
									});
								}
							},
						});
					},
				});
			} else {
				errorHint.classList.remove('hidden');
			}
		});

		// copy url to clipboard
		const copyWishListUrlToClipboardBtn = document.querySelector('.copy-wishlist-url-to-clipboard-btn');
		copyWishListUrlToClipboardBtn.addEventListener('click', () => {
			const input = document.querySelector('.copy-wishlist-url-to-clipboard-input');
			input.select();

			// for mobile devices
			input.setSelectionRange(0, 99999);

			// copy to clipboard
			document.execCommand('copy');

			// show success message
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: 'Url is copied!',
				showCloseButton: true,
				showCancelButton: false,
				focusConfirm: true,
				confirmButtonText: '<i class="fa fa-fw fa-check"></i> Great!',
				confirmButtonAriaLabel: 'Great!',
			});
		});
	}

	// validate email
	checkEmailIsValid(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	// show modal with product item details
	showWishListProductItemDetailsModal(wishListItem, event) {
		// check that target is not icon
		if (!event.target.classList.contains('fa')) {

			// product id
			const productId = wishListItem.dataset.productId;

			// wish list item id
			const wishListItemId = wishListItem.dataset.wishListItemId;

			// product options modal content template
			const productOptionsModalContentTemplate = document.querySelector('.wish-list-product-options-modal-content-template[data-wish-list-item-id="' + wishListItemId + '"]');

			// form action url
			const formActionUrl = productOptionsModalContentTemplate.dataset.formActionUrl;

			// set modal html
			const modalHtml = '<form method="post" action="' + formActionUrl + '">' + productOptionsModalContentTemplate.innerHTML + '</form>';

			// show modal
			Swal.fire({
				title: 'Choose product options',
				icon: 'question',
				html: modalHtml,
				focusConfirm: true,
				confirmButtonText: '<i class="fa fa-check"></i> Add to cart!',
				confirmButtonAriaLabel: 'Add to cart!',
				preConfirm: () => {
					// default has errors value
					let hasErrors = false;

					// get drop down lists
					const productOptionDropDownLists = document.querySelectorAll('#swal2-html-container .product_options.form_view .select-inputs select');

					// check all drop down lists values
					for (const productOptionDropDownList of productOptionDropDownLists) {
						if (productOptionDropDownList.value === '') {
							hasErrors = true;
						}
					}

					// show error
					if (hasErrors === true) {
						Swal.showValidationMessage(
							`Please, choose value for each drop down list!`
						);
					}
				},
			}).then((result) => {
				if (result.value) {
					// get product options form
					const productOptionsForm = document.querySelector('#swal2-html-container form');

					// form data
					const formData = new FormData(productOptionsForm);

					// set product id
					formData.append('product_id', productId);

					// set wish list item id
					formData.append('wishlist_id', wishListItemId);

					// asc_action
					formData.append('asc_action', 'UpdateWishlistContent');

					// show loader
					Swal.fire({
						title: 'Saving product to the cart..',
						allowOutsideClick: () => !Swal.isLoading(),
						didOpen: () => {
							// show loader
							Swal.showLoading();

							// update current wish list position (set new options)
							$.ajax({
								// url: '/wishlist.php',
								url: location.pathname,
								type: 'POST',
								dataType: 'json',
								processData: false,
								contentType: false,
								data: formData,
								statusCode: {
									// request is done
									200: function () {
										// add to cart
										$.ajax({
											url: '/cart.php?asc_action=AddToCart/wl_id=' + wishListItemId,
											type: 'GET',
										});
									}
								},
							});

							// show sweetalert message
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: 'Product is successfully added to the cart!',
								showCloseButton: true,
								showCancelButton: true,
								focusConfirm: false,
								confirmButtonText: '<i class="fa fa-fw fa-shopping-cart"></i> Great! Go to the cart!',
								confirmButtonAriaLabel: 'Great! Go to the cart!',
								cancelButtonText: '<i class="fa fa-fw fa-check"></i> or Continue here',
								cancelButtonAriaLabel: 'or Continue here'
							}).then((result) => {
								// redirect to the cart
								if (result.value) {
									location.href = '/cart.php';
								}
							});
						}
					});
				}
			});
		}
	}

	// remove drop down list tooltip on mouseover
	removeDropdownListTooltipOnMouseOver(productOptionDropdownList) {
		$('select[name="' + productOptionDropdownList.name + '"]').tooltip('destroy');
	}
}

// helpful links modals init
class HelpFulLinksModalsInit {

	// constructor
	constructor() {

		// show modal buttons
		const showModalButtons = document.querySelectorAll('.click2id');

		// loop
		for (const showModalButton of showModalButtons) {
			showModalButton.addEventListener('click', this.showModalButtonClick.bind(this, showModalButton));
		}
	}

	// show modal button click
	showModalButtonClick(button) {
		// button id
		const buttonId = button.id;

		// Get the modal
		const modal = document.querySelector('#' + buttonId + '_div');
		modal.style.display = "flex";

		// get the <span> element that closes the modal
		$(".close").click(function () {
			modal.style.display = "none";
			$(".resultstore2").hide();
			$(".resultstore").hide();
		});

		// When the user clicks anywhere outside of the modal, close it
		window.onclick = function (event) {
			if (event.target === modal) {
				modal.style.display = "none";
				$(".resultstore2").hide();
				$(".resultstore").hide();
			}
		}
	}
}

// my size and fit
class MySizeAndFitInit {
	// constructor
	constructor() {
		this.form = document.querySelector('.size-and-fit-content form');
		const submitButtons = document.querySelectorAll('.btn-submit');
		for (const submitButton of submitButtons) {
			submitButton.addEventListener('click', this.formSubmit.bind(this));
		}
	}

	// form submit
	formSubmit(event) {
		// prevent default
		event.preventDefault();

		// show saving data alert
		Swal.fire({
			title: 'Saving data to the database ..',
			allowOutsideClick: () => !Swal.isLoading(),
			didOpen: () => {
				// show loader
				Swal.showLoading();

				// submit form
				this.form.submit();
			}
		});
	}
}

// my brands
class MyBrandsInit {
	// constructor
	constructor() {
		this.form = document.querySelector('.my-brands-content form');
		const submitButtons = document.querySelectorAll('.btn-submit');
		for (const submitButton of submitButtons) {
			submitButton.addEventListener('click', this.formSubmit.bind(this));
		}

		// checkboxes
		const brandCheckboxes = document.querySelectorAll('.my-brand-item-wrapper .icon-wrapper');
		for (const brandCheckbox of brandCheckboxes) {
			brandCheckbox.addEventListener('click', this.toggleCheckbox.bind(this, brandCheckbox));
		}
	}

	// form submit
	formSubmit(event) {
		// prevent default
		event.preventDefault();

		// show saving data alert
		Swal.fire({
			title: 'Saving selected brands to your favorite ..',
			allowOutsideClick: () => !Swal.isLoading(),
			didOpen: () => {
				// show loader
				Swal.showLoading();

				// submit form
				this.form.submit();
			}
		});
	}

	// toggle checkbox
	toggleCheckbox(checkbox) {
		const isActive = checkbox.classList.contains('active');
		const input = checkbox.parentElement.querySelector('input');
		if (isActive) {
			// disable
			checkbox.classList.remove('active');
			input.value = 0;
		} else {
			// enable
			checkbox.classList.add('active');
			input.value = 1;
		}
	}
}

// facebook pixel
class FacebookPixelInit {
	constructor() {
		// get subtotal price selector
		const subTotalSelector = document.querySelector('.subtotal .total-table tr:first-child td:nth-child(2)');
		if (subTotalSelector !== null) {
			// get subtotal price value
			const subTotal = subTotalSelector.textContent.replace('$', '');

			// facebook pixel code
			fbq('track', 'initiateCheckout', {currency: 'USD', value: subTotal});
		}
	}
}

// registration google captcha
class RegistrationGoogleCaptchaInit {
	constructor() {
		this.regForm = document.querySelector('.user-registration-form');

		// check form is null
		if (this.regForm !== null) {
			this.googleCaptchaPublicKey = this.regForm.dataset.googleCaptchaPublicKey;
			this.googleCaptchaTokenInput = document.querySelector('#google-captcha-token-input');

			// attach submit event handler
			this.regForm.addEventListener('submit', this.regFormSubmit.bind(this));
		}
	}

	// form submit protection
	regFormSubmit(event) {
		event.preventDefault();
		grecaptcha.ready(() => {
			grecaptcha.execute(this.googleCaptchaPublicKey, {action: 'submit'}).then((token) => {
				// set token value
				this.googleCaptchaTokenInput.value = token;

				// submit form
				this.regForm.submit();
			});
		});
	}
}

// klaviyo client init
class KlaviyoClientInit {

	// create viewed product event
	createViewedProductEvent(productId) {
		// send a request to the server
		$.ajax({
			url: '/avactis-extensions/klaviyo/actions/public/create-viewed-product-event.php',
			type: 'POST',
			dataType: 'json',
			data: {
				product_id: productId
			},
		});
	}

	// create started checkout event
	createStartedCheckoutEvent() {
		// send a request to the server
		$.ajax({
			url: '/avactis-extensions/klaviyo/actions/public/create-started-checkout-event.php',
			type: 'POST',
			dataType: 'json',
		});
	}

	// create added to cart event
	createAddedToCartEvent(form) {
		// get product id
		const productIdInput = form.querySelector('input[name="prod_id"]');
		const productId = productIdInput.value;


		// get product options
		const productOptions = [];
		const productOptionInputs = form.querySelectorAll('select[name^="po"]');
		for (const productOptionInput of productOptionInputs) {
			const productOptionId = productOptionInput.name.match(/[0-9]+/)[0];
			productOptions.push({
				option_id: productOptionId,
				value_id: productOptionInput.value
			});
		}

		// send a request to the server
		$.ajax({
			url: '/avactis-extensions/klaviyo/actions/public/create-added-to-cart-event.php',
			type: 'POST',
			dataType: 'json',
			data: {
				product_id: productId,
				product_options: productOptions
			},
			success: (data) => {
				form.submit();
			}
		});
	}

	// create
	createRequestShoeCatalogEvent(data) {
		klaviyo.push(['track', 'Request Shoe Catalog', data]);
	}
}

// add to cart btn init
class AddProductToFormSubmitInit {

	// constructor
	constructor(klaviyoClient) {
		this.form = document.querySelector('.product-info-body .product-details form');
		this.klaviyoClient = klaviyoClient;
		if (this.form !== undefined) {
			this.form.addEventListener('submit', this.formSubmit.bind(this));
		}
	}

	// submit
	formSubmit(event) {
		// stop submit
		event.preventDefault();

		// create event
		this.klaviyoClient.createAddedToCartEvent(this.form);
	}

}

// request show catalog init
class RequestShoeCatalogInit {

	// data
	data = {};

	// has errors
	validationErrors = [];

	// constructor
	constructor(klaviyoClient) {
		this.form = document.querySelector('#email_signup');
		this.klaviyoClient = klaviyoClient;
		if (this.form !== undefined) {
			// google recaptcha
			this.googleCaptchaPublicKey = this.form.dataset.googleCaptchaPublicKey;

			// create a new variable, because binding return new function
			this.formSubmitBindFunction = this.formSubmit.bind(this);

			// on submit
			this.form.addEventListener('submit', this.formSubmitBindFunction);
		}
	}

	// submit
	formSubmit(event) {
		// stop submit
		event.preventDefault();

		// show saving data alert
		Swal.fire({
			title: 'Processing...',
			allowOutsideClick: () => !Swal.isLoading(),
			didOpen: () => {
				// show loader
				Swal.showLoading();

				// populate data
				this.populateData();

				// validate data
				this.validateData();

				// create event
				if (this.validationErrors.length === 0) {

					// check via google recaptcha
					grecaptcha.ready(() => {
						grecaptcha.execute(this.googleCaptchaPublicKey, {action: 'submit'}).then((token) => {
							// send a request to the server
							$.ajax({
								url: '/avactis-extensions/klaviyo/actions/public/google-captcha-validator.php',
								type: 'POST',
								dataType: 'json',
								data: {
									token: token
								},
								success: (data) => {
									// request is verified
									if (data.status === true) {
										// send api request
										this.klaviyoClient.createRequestShoeCatalogEvent(this.data);

										// remove current form submit event handler
										this.form.removeEventListener('submit', this.formSubmitBindFunction);

										// resubmit the form using the default klaviyo behavior
										this.form.dispatchEvent(new Event('submit'));

										// show success message
										Swal.fire({
											icon: 'success',
											title: 'Success',
											text: 'Thank you for requesting your catalog! We will get it in the mail to you shortly.',
											showCloseButton: false,
											showCancelButton: false,
											focusConfirm: true,
											confirmButtonText: '<i class="fa fa-fw fa-check"></i> Great!',
											confirmButtonAriaLabel: 'OK',
										});
									}
								}
							});
						});
					});
				} else {
					// show validation error
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: this.validationErrors.join('<br/>'),
						showCloseButton: false,
						showCancelButton: false,
						focusConfirm: true,
						confirmButtonText: '<i class="fa fa-fw fa-check"></i> OK',
						confirmButtonAriaLabel: 'OK',
					});
				}
			}
		});
	}

	// populate data
	populateData() {
		this.data = {
			'$first_name': this.form.querySelector('input[name="$first_name"]').value,
			'$last_name': this.form.querySelector('input[name="$last_name"]').value,
			'$email': this.form.querySelector('input[name="email"]').value,
			'$address1': this.form.querySelector('input[name="$address1"]').value,
			'$city': this.form.querySelector('input[name="$city"]').value,
			'$region': this.form.querySelector('input[name="$region"]').value,
			'$zip': this.form.querySelector('input[name="$zip"]').value,
			'$country': this.form.querySelector('input[name="$country"]').value,
			'$phone_number': this.form.querySelector('input[name="$phone_number"]').value,
			'Birthday': this.form.querySelector('input[name="Birthday"]').value,
			'Favorite Shoe Brand': this.form.querySelector('select[name="Favorite Shoe Brand"]').value,
			'Shoe Size': this.form.querySelector('select[name="Shoe Size"]').value,
			'Shoe Width': this.form.querySelector('select[name="Shoe Width"]').value
		};
	}

	// validate data
	validateData() {
		// clear validation errors
		this.validationErrors = [];

		// required fields
		const requiredFields = [
			'$first_name',
			'$last_name',
			'email',
			'$address1',
			'$city',
			'$region',
			'$zip',
			'$country'
		];
		for (const requiredField of requiredFields) {

			// get current value
			const requiredFieldInput = this.form.querySelector('input[name="' + requiredField + '"]');

			// get value
			const requiredFieldValue = requiredFieldInput.value;

			// check value is null
			if (requiredFieldValue === null || requiredFieldValue === undefined || requiredFieldValue === '') {
				// get input label
				const label = requiredFieldInput.closest('._form_element').querySelector('label').innerText.replace(/(\*|\s{2,})/, '');

				// push validation error message
				this.validationErrors.push(label + ' is required!');
			}
		}

		// check email is correct
		const email = this.form.querySelector('input[name="email"]').value;
		if (email !== '') {
			if (this.validateEmail(email) === false) {
				this.validationErrors.push('Incorrect email address!');
			}
		}
	}

	// validate email
	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

}

// cart local storage
class CartLocalStorageInit {

	// constants
	static LOCAL_STORAGE_KEY_CART_POSITIONS = 'cartPositions';
	static LOCAL_STORAGE_KEY_CART_POSITIONS_UPDATED_AT = 'cartPositionsUpdatedAt';

	// cart positions
	positions = [];

	// constructor
	constructor() {
		// parse current cart positions from html
		this.parsePositionsFromHtml();

		// check if we need to restore the cart
		// time from the last update is more than php session life: 1440 seconds
		if (this.checkNeedToRestore()) {
			console.log('Cart: need to restore');

			// check the last local storage data
			this.getPositionsFromLocalStorage();

			// replace current cart by positions from local storage
			this.restorePositionsFromLocalStorage();
		} else {
			console.log('Cart: no need to restore');

			// save positions to local storage
			this.savePositionsToLocalStorage();
		}
	}

	// check need to restore
	checkNeedToRestore() {
		console.log('Cart: check need to restore');

		// return true;

		// check if there are no positions
		if (this.positions.length !== 0) {
			return false;
		}

		// update at
		const updatedAt = localStorage.getItem(CartLocalStorageInit.LOCAL_STORAGE_KEY_CART_POSITIONS_UPDATED_AT);

		// value is not exist yet
		if (updatedAt === null) {
			// console.log('no updated at value');
			return false;
		}

		// seconds passed
		const secondsPassed = (Date.now() - parseInt(updatedAt)) / 1000;

		// show seconds passed
		console.log('Cart: passed: ' + secondsPassed.toString() + ' seconds');

		// check time from the last update is more than php session life: 1440 seconds
		return secondsPassed >= 1440;
	}

	// parse positions from html
	parsePositionsFromHtml() {
		// console.log('parse from html');

		const cartContentJsonElement = document.querySelector('#cart-content-json');
		if (cartContentJsonElement !== null) {
			const cartContentJson = JSON.parse(cartContentJsonElement.textContent);
			for (const positionData of cartContentJson) {

				// parse options
				const positionOptions = {};
				for (const key in positionData['options']) {
					if (positionData['options'].hasOwnProperty(key)) {
						positionOptions[key] = positionData['options'][key];
					}
				}

				// add to map
				this.positions.push({
					'product_id': parseInt(positionData['productId']),
					'quantity': parseInt(positionData['quantity']),
					'options': positionOptions,
				});
			}
		}
	}

	// get cart content from local storage
	getPositionsFromLocalStorage() {
		console.log('Cart: get positions from local storage');

		// get json data
		const positions = localStorage.getItem(CartLocalStorageInit.LOCAL_STORAGE_KEY_CART_POSITIONS);

		// check response is null
		if (positions !== null) {
			// convert from string to json object
			this.positions = JSON.parse(positions);
		}
	}

	// restore cart content from local storage
	restorePositionsFromLocalStorage() {
		console.log('restore positions from local storage');

		// check location
		if (location.pathname === '/cart.php') {
			console.log('cart.php');

			Swal.fire({
				title: 'Restoring your shopping cart..',
				allowOutsideClick: () => !Swal.isLoading(),
				didOpen: () => {
					// show loader
					Swal.showLoading();

					// send request
					this.sendCartRestorePositionsRequest(true);
				},
			});
		} else {
			this.sendCartRestorePositionsRequest(false);
		}

		// update timestamp
		this.updatePositionsTimestamp();
	}

	// send cart restore positions request
	sendCartRestorePositionsRequest(showSuccessMessage) {
		console.log('changing cart');

		$.ajax({
			url: '/cart.php?asc_action=ChangeCart',
			type: 'POST',
			dataType: 'json',
			data: {
				positions: this.positions,
			},
			statusCode: {
				200: (data) => {
					// update cart counter
					const myCartUrls = document.querySelectorAll('.my-cart-url');
					for (const myCartUrl of myCartUrls) {
						myCartUrl.textContent = 'My Cart (' + this.positions.length.toString() + ')';
					}

					// show success message
					if (showSuccessMessage) {
						$.ajax({
							url: '/cart_content.php',
							type: 'GET',
							success: function (data) {
								// replace the content
								const shoppingCartWrapper = document.querySelector('#shopping-cart-wrapper');
								shoppingCartWrapper.innerHTML = data;

								// close sweetalert window
								swal.close();
							}
						});
					}

					// log
					// console.log('cart is restored');
					// console.dir(this.positions);
				}
			}
		});
	}

	// save cart content to local storage
	savePositionsToLocalStorage() {
		// console.log('save positions to local storage');

		// save positions
		localStorage.setItem(CartLocalStorageInit.LOCAL_STORAGE_KEY_CART_POSITIONS, JSON.stringify(this.positions));

		// update timestamp
		this.updatePositionsTimestamp();
	}

	// update positions timestamp
	updatePositionsTimestamp() {
		localStorage.setItem(CartLocalStorageInit.LOCAL_STORAGE_KEY_CART_POSITIONS_UPDATED_AT, Date.now().toString());
	}
}

// check is email
function checkStringIsEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

// check is ie
function isIE() {
	// check the userAgent property of the window.navigator object
	const ua = window.navigator.userAgent;

	// ie 10 or older
	const msie = ua.indexOf('MSIE ');

	// ie 11
	const trident = ua.indexOf('Trident/');

	// output
	return (msie > 0 || trident > 0);
}

// jquery document ready
$(function () {
	let klaviyoClient = null;

	// klaviyo js api
	if (isIE()) {
		console.log('ie');
	} else {
		console.log('not ie');
		klaviyoClient = new KlaviyoClientInit();
	}

	// check is product-info-page
	if (document.body.classList.contains('product-info-body')) {
		const productId = document.body.dataset.productId;

		// check klaviyo client is null
		if (klaviyoClient !== null) {
			klaviyoClient.createViewedProductEvent(productId);

			// add to cart btn
			new AddProductToFormSubmitInit(klaviyoClient);
		}
	}

	// init add product to wish list feature
	// can't attach it to the product-info.php because of human friendly url
	new AddProductToWishListInit();
	new CartLocalStorageInit();

	// switch location pathname
	switch (location.pathname) {

		// checkout
		case '/checkout.php':
			const emailInput = document.querySelector('input[name="billingInfo[Email]"]');
			if (emailInput !== undefined) {
				// current value (on page load)
				const email = emailInput.value;
				if (klaviyoClient !== null && checkStringIsEmail(email)) {
					klaviyoClient.createStartedCheckoutEvent(email);
				}

				// on focus out
				emailInput.addEventListener('focusout', () => {
					const email = emailInput.value;
					if (klaviyoClient !== null && checkStringIsEmail(email)) {
						klaviyoClient.createStartedCheckoutEvent(email);
					}
				});
			}
			break;

		// manage wish list init
		case '/wishlist.php':
		case '/wishlist-share.php':
			new ManageWishListInit();
			break;

		// my size and fit init
		case '/my-size-and-fit.php':
			new MySizeAndFitInit();
			break;

		// my brands init
		case '/my-brands.php':
			new MyBrandsInit();
			break;

		// some cart logic
		case '/cart.php':
			//new FacebookPixelInit();
			break;

		// request-shoe-catalog
		case '/request-shoe-catalog.php':
		case '/request-shoe-catalog-2.php':
			if (klaviyoClient !== null) {
				new RequestShoeCatalogInit(klaviyoClient);
			}
			break;
	}

	// helpful links modals init
	new HelpFulLinksModalsInit();

	// registration google captcha
	new RegistrationGoogleCaptchaInit();

	// bootstrap tooltips
	$('[data-toggle="tooltip"]').tooltip();

	// remove success_message on click
	$('.success_message').click(function () {
		$(this).remove();
	});

	// remove warning_message on click
	$('.warning_message').click(function () {
		$(this).remove();
	});

	// remove error_message on click
	$('.error_message').click(function () {
		$(this).remove();
	});

	// remove info_message on click
	$('.info_message').click(function () {
		$(this).remove();
	});

	// add a search loader
	$('form[name="CatalogSearchForm"]').submit(function (e) {
		let timerInterval;
		Swal.fire({
			title: 'Searching ..',
			html: 'We are finding products that match your search terms ..',
			timer: 30000,
			timerProgressBar: true,
			didOpen: () => {
				Swal.showLoading();
			},
			willClose: () => {
				clearInterval(timerInterval);
			}
		});
		// e.preventDefault();
	});

});